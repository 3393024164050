import React, { useContext, useEffect, useRef, useState } from 'react';
import { createAuthWebSocket } from '../../../services/auth.service';
import { NotificationType } from '../constants';

const GEO_POSITION_UPDATE_TIME = 5000

const useInterventionAmbulanceSocket = ({ interventionAmbulanceId, isAmbulanceTeamChief = false }) => {
  const [interventionAmbulanceSocket, setInterventionAmbulanceSocket] = useState(null);
  const [interventionAmbulanceSocketMessage, setSocketMessage] = useState(null);

  useEffect(() => {
    if (!interventionAmbulanceId) return

    const newSocket = createAuthWebSocket({
      resource: "intervention_ambulances",
      roomName: interventionAmbulanceId
    })

    newSocket.onopen = () => {
      console.log('InterventionAmbulance WebSocket connected');
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setSocketMessage(data)
    };

    newSocket.onclose = () => {
      console.log('InterventionAmbulance WebSocket disconnected');
    };

    setInterventionAmbulanceSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [interventionAmbulanceId]);

  useEffect(() => {
    if (!(interventionAmbulanceSocket && isAmbulanceTeamChief && "geolocation" in navigator)) return
    console.log("-------Good interval ")
    const interval = setInterval(() => {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log(`-------currPosition ${position.coords.longitude}:${position.coords.latitude}`)
        interventionAmbulanceSocket.send(
          JSON.stringify({
            "geo_position": `${position.coords.longitude}:${position.coords.latitude}`
          })
        )
      });
    }, GEO_POSITION_UPDATE_TIME);

    return () => clearInterval(interval);
  }, [interventionAmbulanceSocket, isAmbulanceTeamChief])

  return [interventionAmbulanceSocketMessage, interventionAmbulanceSocket]
}

export default useInterventionAmbulanceSocket;
