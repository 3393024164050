import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get_stock_movements, get_product, get_products } from '../../services/management/product_stock.service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CardStatOne from '../../components/cards/stats/CardStatOne';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Toast } from 'primereact/toast';
import Heading from '../../components/Utils/Heading';
import Loading from '../../components/Utils/Loading';


const STOCK_ACTION_MAP_DISPLAY = {
  "supply": {label: "Entrée de stock", color: "green"},
  "use": {label: "Sortie de stock", color: "red"},
}

const StockMaterialHistory = () => {
  const params = useParams()
  const [history, setHistory] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState('')
  const toast = useRef(null);

  const [error, setError] = useState('');

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    seconde: "numeric",
    timeZone: "UTC"
  };

  useEffect(() => {
    const fetch_data = async () => {
      try {
        const response = await get_stock_movements(params.id, 1000, 0, { filter: filterType });
        const productResponse = await get_product(params.id)
        setHistory(response.results);

        setProduct(productResponse)
        setLoading(false);
      } catch (e) {
        console.log("-----err ", e)
        setError(e.message);
        setLoading(false);
      }
    };

    fetch_data();
  }, [params]);

  const product_types = {
    'medical-equipment': 'Équipement matériel',
    'medication': 'Médicament'
  }

  const product_type_template = (history) => {
    return product_types[history.product.product_type]
  };

  const product_name_template = (history) => {
    return history.product.name
  }

  const action_type_template = (history) => {
    return (
      <span className={`text-md font-bold mx-auto text-${STOCK_ACTION_MAP_DISPLAY[history.action].color}-500 cursor-pointer`}>
        {STOCK_ACTION_MAP_DISPLAY[history.action].label}
      </span>
    )
    // console.log("---------history ", history)
    switch (history.action) {
      case 'add-product':
        return (
          <Icon icon={'mdi:format-font-size-increase'} className="text-3xl mx-auto text-green-400 cursor-pointer" />
        )
      case 'substract-product':
        return (
          <Icon icon={'mdi:format-font-size-decrease'} className="text-3xl mx-auto text-red-500 cursor-pointer" />
        )
      default:
        return null
    }
  }

  const history_date_template = (history) => {
    return new Intl.DateTimeFormat("fr-BJ", options).format(new Date(history.created_at))
  }

  const applyFilter = async (event) => {
    const filter = event.target.value
    setFilterType(filter)
    const data = await get_stock_movements(params.id, 1000, 0, { action: filter });
    setHistory(data.results);
  }


  return (
    <React.Fragment>
      <Toast ref={toast} />


      {/* Page content */}
      <div className="p-4 lg:p-8">

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <Loading />
          </div>
        ) : (
          <div>
            {error ? (
              <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
            ) :
              (
                <React.Fragment>
                  <Heading title={'Historique : ' + product.name} />
                  <div className="flex justify-between items-center">
                    <div className="flex items-center my-8">
                      <CardStatOne icon="mdi:ambulance" title="Mouvement(s)" value={history.length} bg_color="bg-primary" />
                    </div>
                  </div>
                  <div className="flex justify-end gap-3 items-center p-2">
                    <label htmlFor="type" className="block mb-2 text-base font-medium text-gray-900">Filtrer par : </label>
                    <select id="type" name="type" value={filterType} onChange={applyFilter} className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring-primary ease-linear transition-all duration-150 w-1/6" required>
                      <option value="">Tout</option>
                      <option value="supply">Ajout</option>
                      <option value="use">Retrait</option>
                    </select>
                  </div>
                  {history.length === 0 ? (
                    <p className="text-center my-8 text-xl font-semibold"> Aucune historique disponible !</p>

                  ) :
                    (
                      <div className="card my-4">
                        <DataTable value={history} showGridlines={true} sortMode="multiple" removableSort paginator rows={10} tableStyle={{ minWidth: '50rem' }}>
                          <Column field="created_at" body={history_date_template} header="Date" sortable style={{ width: '25%' }}></Column>
                          <Column field="product" body={product_name_template} header="Produit" sortable style={{ width: '25%' }}></Column>
                          <Column field="product" header="Type" body={product_type_template} sortable style={{ width: '20%' }}></Column>
                          <Column field="action" header="Action" body={action_type_template} sortable style={{ width: '10%' }}></Column>
                          <Column field="quantity" header="Quantité" sortable style={{ width: '20%' }}></Column>
                        </DataTable>
                      </div>
                    )}
                </React.Fragment>
              )
            }
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default StockMaterialHistory
