import { Tooltip } from "primereact/tooltip";
import DefaultAvatar from "../../assets/images/default-user.jpg";

const MedicalMember = ({num, first_name, last_name, roles, on_click}) => {

    const roles_fr = {
        "admin" : "Directeur général",
        "arm" : "Assistant de régulation médicale",
        "company-director" : "Directeur de société",
        "branch-director" : "Directeur de branche",
        "chief-supervisor" : "Surveillant",
        "branch-supervisor" : "Superviseur de branche",
        "medical-regulator" : "Medecin régulateur",
        "ambulance-chief" : "Chef d'ambulance",
        "ambulance-member" : "Membre d'équipe d'intervention",
        "ambulance-driver" : "Chauffeur d'ambulance",
        "emergency-nurse" : "Urgentiste",
        "rescuer" : "Secouriste",
        "permanent-staff" : "Personnel permanant",
    };
    const fr_role = typeof roles[0] === 'string' ? roles_fr[roles[0]] : roles_fr[roles[0]?.name];
    const is_long_fr_role = fr_role.length > 18;

    return (
        <div id={`medical-${num}`} className="bg-tripleLightBlue rounded flex flex-wrap items-center justify-between gap-4 w-fit md:w-[400px] cursor-pointer p-4 md:px-8" onClick={() => on_click(num)}>
            <div className="card-left rounded-md">
                <img className="rounded-md w-24 h-24" src={DefaultAvatar} alt="Avatar par défaut membre du personnel"/>
            </div>
            <div className="card-right text-left p-4 min-h-32 flex items-center">
                <ul className="ml-4 space-y-2 text-gray-800 list-none">
                    <li className="mb-2 font-semibold text-primary">{last_name}</li>
                    <li className="mb-4 font-semibold text-primary">{first_name}</li>
                    <li 
                        className={`font-normal text-gray-500 uppercase text-sm w-44 truncate ${is_long_fr_role ? 'tooltip-target' : ''}`}
                        data-pr-tooltip={is_long_fr_role ? fr_role : ''}
                        data-pr-position="bottom"
                    >
                        {fr_role}
                    </li>
                </ul>
            </div>
            {is_long_fr_role && <Tooltip target=".tooltip-target" />}
        </div>
    )    
}

export default MedicalMember